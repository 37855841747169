<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Search by Code
            </h2>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        v-model="search"
                        prepend-icon="mdi-database-search"
                        hide-details
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                :disabled="!search || search.length == 0"
                @click="searchWO"
            >
                SEARCH
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'FindWO',
    data: () => ({
        loading: false,
        search: null,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async searchWO() {
            try {
                this.loading = true
                const response = await API.findWOByCode(
                    this.search.toUpperCase()
                )
                if (response) {
                    const formattedWO = this.formatItems(response)
                    this.$emit('addWO', formattedWO)
                    this.close()
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            if (key == 'assemblyItems') {
                                let assemblyItems = []
                                Object.keys(
                                    workOrder.partNumbers[partNumberId][key]
                                ).forEach(aiKey => {
                                    assemblyItems.push({
                                        partNumberId: aiKey,
                                        ...workOrder.partNumbers[partNumberId][
                                            key
                                        ][aiKey],
                                    })
                                })
                                item[key] = assemblyItems
                            } else {
                                item[key] =
                                    workOrder.partNumbers[partNumberId][key]
                            }
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
    },
}
</script>

<style></style>
